import { StudyItem } from '@/types'
import { HigherEducationPointsTable } from './HigherEducationPointsTable'
import { HigherEducationPathRequirements } from './HigherEducationPathRequirements'
import { OpenInNew } from '@mui/icons-material'

interface Props {
    studyItem: StudyItem
    accordionOpen: boolean
    quotasExpanded: boolean
    setQuotasExpanded: (value: boolean) => void
}

export function HigherEducationPathDetails({
    studyItem,
    accordionOpen,
    quotasExpanded,
    setQuotasExpanded,
}: Props) {

    const sortedQuotas = studyItem.study_quotas.map((q) => ({
        ...q,
        quotas: [...q.quotas].sort(
            (a, b) => parseInt(b.admission_year) - parseInt(a.admission_year)
        ),
    }))

    const sortedQuotaYears = [...studyItem.quota_years].sort(
        (a, b) => parseInt(b) - parseInt(a)
    )

    return (
        <div
            className={`grid overflow-hidden text-sm grid-rows-[1fr] animate-reveal`}
        >
            <div
                className={`overflow-hidden md:border md:rounded-xl flex flex-col justify-center ${accordionOpen ? 'mt-4' : 'mt-0'}`}
            >
                <div className="grid grid-cols-1 gap-2 md:grid-cols-[1fr,3fr,1.5fr] p-0 md:p-2">
                    <div
                        id="info-div"
                        className="flex-1 border border-black p-2 md:p-0 md:border-0 rounded-md justify-center items-center"
                    >
                        <div id="heltid-div">
                            <p className="font-bold">Info</p>
                            <p>{studyItem.study_programme_type.name}</p>
                            {studyItem.is_full_time ? (
                                <p>Heltid</p>
                            ) : (
                                <p>Deltid</p>
                            )}
                            {studyItem.start_month === 1 ? 'Vår' : 'Høst'}
                            <div>
                                {studyItem.gender_points && (
                                    <div>
                                        <p>Kjønnspoeng</p>
                                        <li>
                                            Poeng:{' '}
                                            {studyItem.gender_points.points} (
                                            {studyItem.gender_points.gender ===
                                            'M'
                                                ? 'Mann'
                                                : 'Kvinne'}
                                            )
                                        </li>
                                    </div>
                                )}
                            </div>
                            <div className="flex md:flex-col justify-between items-center md:justify-start md:items-start">
                                <p className={'py-2'}>{studyItem.req_code}</p>
                                <a
                                    href={studyItem.url}
                                    target={'_blank'}
                                    className="text-blue-600 flex items-center gap-1"
                                    rel="noreferrer"
                                >
                                    Om studiet <OpenInNew fontSize="small" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div>
                        <HigherEducationPointsTable
                            quotas={sortedQuotas}
                            quotaYears={sortedQuotaYears}
                            isNew={studyItem.is_new}
                            quotasExpanded={quotasExpanded}
                            onQuotasExpandedChange={setQuotasExpanded}
                        />
                    </div>

                    <div
                        id="req-external"
                        className="border border-black md:border-0 p-2 md:p-0 rounded-md justify-center items-center"
                    >
                        <div className="flex flex-col justify-center">
                            <HigherEducationPathRequirements
                                reqCode={studyItem.req_code}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
